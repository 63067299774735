<template>
  <div class="upload_graphocs_warp">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">发布视频</div>
      <router-link class="rightBtn" to="/releaseRules">发布规则?</router-link>
    </div>
    <div class="main">
      <div class="input_box">
        <van-field
          class="titleText"
          v-model="title"
          rows="4"
          autosize
          type="textarea"
          maxlength="150"
          placeholder="请输入标题,至少3个字"
          show-word-limit
        />
      </div>
      <div class="content">
        <div class="subTitle">添加（封面/视频）</div>
        <div class="up_box">
          <div class="up_img_box">
            <UploadFile @uploadSuccess="uploadSuccess" @delImg="delImg" ref="UploadFile" maxCount="1">
              <svg-icon icon-class="tip_img" />
              <span class="text">上传封面</span>
            </UploadFile>
          </div>
          <!--  上传视频-->
          <div class="up_video_box">
            <video class="previewVideo" id="video" style="object-fit: cover" v-show="false"></video>
            <!-- 上传进度 -->
            <div class="upload_progres" v-if="startUploadVideo">
              <!--进度条-->
              <div class="progress-wrap">
                <div class="progress-inner">
                  <div
                    :class="[
                      'progress-nums',
                      {
                        active: uploadStatue.statusText == '上传成功',
                      },
                    ]"
                    :style="{ width: uploadStatue.percent + '%' }"
                  ></div>
                </div>
                <!-- 文案 -->
                <div class="upload_text">
                  <svg-icon icon-class="success" v-show="uploadStatue.statusText == '上传成功'" />
                  <span v-show="uploadStatue.statusText != '上传成功'">
                    {{ uploadStatue.percent + '%' }}
                  </span>
                  <div class="upload_tip">{{ uploadStatue.statusText }}</div>
                </div>
                <!-- 删除 -->
                <div
                  class="video_close"
                  @click="uploadDelete"
                  v-show="uploadStatue.statusText == '上传成功' || uploadStatue.statusText == '上传失败'"
                >
                  <svg-icon icon-class="close8" />
                </div>
              </div>
            </div>
            <div class="update-box" v-show="!startUploadVideo" @click.stop>
              <van-uploader accept="video/*" :max-count="1" :after-read="afterVideo" :before-read="beforeRead">
                <div class="upload-icon">
                  <svg-icon icon-class="tip_upload" />
                  <span>上传视频</span>
                </div>
              </van-uploader>
            </div>
          </div>
          <!--  上传视频-->
        </div>
        <div class="location_box flex-between">
          <div class="location_text">定位</div>
          <div class="location" @click="goCity">
            <div class="ellipsis">{{ location }}</div>
            <svg-icon icon-class="toTheRight" />
          </div>
        </div>
        <div class="location_box flex-between">
          <div class="location_text">制定价格</div>
          <div class="price location" @click="isRewardShow = true">
            <svg-icon icon-class="gold" v-if="price > 0" />
            {{ price > 0 ? price : '免费' }}
            <svg-icon icon-class="toTheRight" />
          </div>
        </div>
        <div class="flex-between tipic">
          <div class="selectTitle">
            <svg-icon icon-class="tip_hot" />
            <span>话题选择</span>
          </div>
          <div class="more" @click="goTag">
            <span>更多</span>
            <svg-icon icon-class="toTheRight" />
          </div>
        </div>
        <div class="tipic_content">
          <div class="tipic_item" v-for="item in tags" :key="item.id">
            <svg-icon icon-class="tag" />
            <div class="ellipsis tipc_text">{{ item.name }}</div>
            <div class="tic_close" @click="delTag(item.name)">
              <svg-icon icon-class="close8" />
            </div>
          </div>
        </div>
      </div>
      <div style="height: 120px"></div>
    </div>
    <div class="publish_box">
      <div class="publish" @click="publish">立即发布</div>
    </div>
    <!-- 设置金额-->
    <div class="reward_warp">
      <van-overlay :show="isRewardShow" />
      <transition name="fade">
        <div class="reward_pop_box" v-if="isRewardShow">
          <div class="reward_pop">
            <h2>设定价格</h2>
            <div class="reward">
              <div :class="['reward_item', { active: i === curMoneyIndex }]" v-for="(amount, i) in amountMatrix" :key="i" @click="changeAmount(i)">
                <svg-icon icon-class="gold" />
                {{ amount }}
              </div>
              <div :class="['reward_item', { active: 5 === curMoneyIndex }]" @click="changeAmount(5)">
                <van-field v-model="customizeMoney" placeholder="其他金额" @input="changeMoney" type="digit" />
              </div>
            </div>
            <div class="balance"></div>
            <div class="reward_btn" @click="setPrice">确定</div>
          </div>
          <div class="close_box">
            <div class="close" @click="isRewardShow = false">
              <svg-icon icon-class="y_close" />
            </div>
          </div>
        </div>
      </transition>
    </div>
    <FullScreenLoading :isLoadingShow="isLoadingShow" />
    <!-- 提示弹窗 -->
    <van-popup class="dialog" v-model="promptShow" :close-on-click-overlay="false">
      <div class="dialogContent">
        <div class="dialogTitle">
          <span>温馨提示</span>
        </div>
        <div class="dialogText">
          <p>今日上传已达上限</p>
          <p>开通会员/大V无限上传</p>
        </div>
        <div class="btnGroup">
          <img @click="$router.push('/memberCentre?t=vip')" class="btn toBinding" src="@/assets/png/toOpenMember.png" />
          <img @click="$router.push('/myCertification')" class="btn toPrepaid" src="@/assets/png/toOpenBigV.png" />
        </div>
      </div>
      <div class="closeBtn" @click="promptShow = false">
        <img src="@/assets/png/promptClose.png" />
      </div>
    </van-popup>
  </div>
</template>
<script>
import UploadFile from '@/components/UploaderFile';
import { getLocalItem } from '@/utils/longStorage';
import { Overlay, Field, Toast, Uploader, Circle } from 'vant';
import { mapGetters } from 'vuex';
import FullScreenLoading from '@/components/FullScreenLoading';
import { publishPost } from '@/api/community';
import SparkMD5 from 'spark-md5';
import { uploadImg, upLoadVideo } from '@/api/app';

const fileReaderPromisify = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    let Spark = new SparkMD5.ArrayBuffer();
    reader.readAsArrayBuffer(file);
    reader.onload = function (e) {
      Spark.append(e.target.result);
      let md5 = Spark.end();
      resolve(md5);
    };
    reader.onerror = (err) => {
      reject(err);
    };
  });
};
export default {
  name: 'uploadVIdeo',
  components: {
    UploadFile,
    [Field.name]: Field,
    [Overlay.name]: Overlay,
    FullScreenLoading,
    [Uploader.name]: Uploader,
    [Circle.name]: Circle,
  },
  computed: {
    ...mapGetters({
      tags: 'tags',
    }),
  },
  data() {
    return {
      location: getLocalItem('city') ? getLocalItem('city') : '选择城市',
      title: '',
      maxSize: 1024 * 1024 * 300, //最大上传视频
      isRewardShow: false, //是否打开大赏弹窗
      amountMatrix: [1, 5, 10, 20, 50],
      customizeMoney: '', //手动输入金额
      curMoneyIndex: -1,
      price: 0, //设置的金额
      imgList: [],
      isLoadingShow: false,
      startUploadVideo: false, //视频是否上传中
      chunk_size: 1024 * 1024 * 2, //分片大小
      retry_request: 5, //重试 加 失败
      uploadStatue: {
        statusText: '正在读取文件...',
        percent: 0, //当前上传文件执行的百分比
      },
      totalPos: -1, // 视频分片总数
      sendVideoSucNum: 0, //分片上传视频，成功收到的返回
      totalProgress: {}, //总进度
      isImgUoloadErr: false, //封面上传是否失败
      requestAll: [], //总请求
      requestNum: 0, //正在请求的数量
      requestMaxNum: 6, //同时最大请求数量
      uploadData: {
        title: '', //标题
        newsType: 'SP', //上传类型 COVER-图集 SP-视频
        tags: [], //视频标签数组
        playTime: 0, //视频时长
        cover: '', //封面
        coverThumb: '', //封面小图
        seriesCover: [], // 图集图片地址
        via: '', //视频来源
        coins: 0, //观看金币
        size: 0, //文件大小
        mimeType: '', //文件类型
        actor: '', //演员名字
        sourceURL: '', //资源url 为SP时 必传
        sourceID: '', //上��视频成功后 返回���ID 为SP时必传
        filename: '', //文件名
        resolution: '', //分辨率
        ratio: 1, //视频比例
        md5: '', //文件摘要
        freeTime: 0, //免费观影时长
        location: {
          city: '',
        }, //地理位置信息
      },
      promptShow: false,
    };
  },
  created() {
    this.amountMatrix = this.$store.getters.appConfig.rewardMoney ? this.$store.getters.appConfig.rewardMoney : [2, 10, 20, 50, 100];
  },
  activated() {
    this.location = getLocalItem('city') ? getLocalItem('city') : '选择城市';
  },
  methods: {
    changeAmount(i) {
      this.curMoneyIndex = i;
    },
    // 改变输入金额
    changeMoney() {
      if (this.customizeMoney > 10000) {
        this.customizeMoney = 10000;
        Toast('作品金额最高只能设置10000金币');
      }
    },
    //删除标签
    delTag(name) {
      this.$store.commit('commnity/DELITEM_TAG', name);
    },
    uploadSuccess(img) {
      this.uploadData.cover = img;
      // this.imgList.push(img)
    },
    delImg() {
      this.uploadData.cover = '';
      // this.imgList.splice(index, 1)
    },
    //设置价格
    setPrice() {
      if (this.curMoneyIndex === 5) {
        if (this.customizeMoney < 5) {
          Toast('最低设置5金币');
          return;
        }
        this.price = this.customizeMoney;
        this.isRewardShow = false;
        this.curMoneyIndex = -1;
        this.customizeMoney = '';
        return;
      }
      if (this.curMoneyIndex >= 0) {
        this.price = this.amountMatrix[this.curMoneyIndex];
        this.curMoneyIndex = -1;
        this.customizeMoney = '';
        this.isRewardShow = false;
        return;
      }
    },
    beforeRead(file) {
      if (file.size > this.maxSize) {
        Toast('视频最大为300M');
        return false;
      }
      return true;
    },
    //上传视频
    async afterVideo(e) {
      this.retry_request = 5; // 恢复重试次数

      this.startUploadVideo = true;
      let file = e.file;
      this.uploadStatue.statusText = '正在获取文件MD5...';
      let fileMd5 = await fileReaderPromisify(file);
      this.uploadStatue.statusText = '获取文件MD5成功...';
      let file_chunks = this.getFileChunks(file);
      let length = file_chunks.length;
      this.totalPos = length;
      for (let index in file_chunks) {
        index = Number(index);
        let chunk = file_chunks[index];
        let chunk_data = await this.getBase64(chunk);
        let opts = {
          pos: index + 1,
          totalPos: length,
          data: chunk_data,
          id: fileMd5,
        };
        this.uploadData.md5 = fileMd5;
        this.requestAll.push(opts);
      }
      this.getVideoPlayerInfo(file); //获取视频宽高
    },
    //限制请求数量
    requestConcurrency() {
      while (this.requestAll.length > 0 && this.requestNum <= this.requestMaxNum && this.requestNum >= 0) {
        let opts = this.requestAll.shift();
        this.upload(opts);
      }
    },
    // 开始上传
    async upload(options) {
      try {
        this.requestNum++;
        let callback = (processCallback) => {
          let percent = parseInt((processCallback.loaded / processCallback.total) * 100);
          this.totalProgress[options.pos - 1] = percent.toFixed(2);
          let strArr = Object.values(this.totalProgress);
          let numberArr = strArr.map(Number);
          this.uploadStatue.percent = this.average(numberArr).toFixed(0);
        };
        let ret = await this.$Api(upLoadVideo, options, undefined, callback);
        if (ret.code == 200) {
          this.sendVideoSucNum++;
          // 分片上传成功的次数
        }
        if (ret.code == 200 && this.uploadStatue.percent == 100 && this.sendVideoSucNum == options.totalPos) {
          this.uploadStatue.statusText = '上传成功';
          this.uploadData.sourceURL = ret.data.videoUri;
          this.uploadData.sourceID = ret.data.id;
          const videoDom = document.getElementById('video');
          videoDom.removeEventListener('loadedmetadata', this.listenerLoaded);
          return;
        }
        if (ret.code != 200) {
          this.retry_request--;
          if (this.retry_request >= 0) {
            this.requestAll.push(options);
          }
          return;
        }
      } catch (e) {
        this.retry_request--;
      } finally {
        this.requestNum--;
        if (this.retry_request >= 0) {
          this.requestConcurrency();
        } else {
          this.uploadStatue.percent = 0;
          this.uploadStatue.statusText = '上传失败';
          this.sendVideoSucNum = 0;
        }
      }
    },
    // 将视频分片
    getFileChunks(file) {
      let { size } = file;
      let total_chunks = Math.ceil(size / this.chunk_size);
      let file_chunks = [];
      if (size > this.chunk_size) {
        for (let i = 0; i < total_chunks; i++) {
          let start = i * this.chunk_size;
          let end = (i + 1) * this.chunk_size;
          let chunk = file.slice(start, end);
          this.totalProgress[i] = 0;
          file_chunks.push(chunk);
        }
      } else {
        file_chunks.push(file);
        this.totalProgress[0] = 0;
      }
      return file_chunks;
    },
    base64toFile(baseUrl, filename = 'file') {
      let arr = baseUrl.split(',');
      let type = arr[0].match(/:(.*?);/)[1]; // 解锁图片类型
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length;
      let bufferArray = new Uint8Array(n);
      while (n--) {
        bufferArray[n] = bytes.charCodeAt(n);
      }
      return new File([bufferArray], filename, {
        type,
      });
    },
    // 获取视频信息
    getVideoPlayerInfo(file) {
      this.uploadStatue.statusText = '获取视频信息中...';
      this.uploadData.size = file.size;
      this.uploadData.filename = file.name;
      this.uploadData.mimeType = file.type;
      this.$nextTick(() => {
        const videoElement = document.getElementById('video');
        videoElement.src = URL.createObjectURL(file);
        videoElement.addEventListener('loadedmetadata', this.listenerLoaded);
      });
    },
    listenerLoaded() {
      const videoDom = document.getElementById('video');
      if (videoDom.duration < 30) {
        this.uploadStatue.percent = 0;
        this.uploadStatue.statusText = '上传失败';
        Toast('请上传30秒以上的视频');
        return;
      }
      this.uploadData.playTime = parseInt(videoDom.duration);
      this.uploadData.ratio = Number((videoDom.videoWidth / videoDom.videoHeight).toFixed(2));
      this.uploadData.resolution = videoDom.videoWidth + 'x' + videoDom.videoHeight;

      this.requestConcurrency();
      this.uploadStatue.statusText = '上传中,请勿退出...';
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        ///FileReader类就是专门用来读文件的
        const reader = new FileReader();
        //开始读文件
        //readAsDataURL: dataurl它的本质就是图片的二进制数据， 进行base64加密后形成的一个字符串，
        reader.readAsDataURL(file);

        reader.onload = () => {
          // 成功和失败返回对应的信息，reader.result一个base64，可以直接使用
          let base64 = reader.result;

          var base64Arr = base64.split(',');
          var base64String = base64;
          if (base64Arr.length > 1) {
            base64String = base64Arr[1];
          }
          return resolve(base64String);
        };
        // 失败返回失败的信息
        reader.onerror = (error) => reject(error);
      });
    },
    //将base64转换为文件对象
    convertBase64ToBlob(base64) {
      var base64Arr = base64.split(',');
      var imgtype = '';
      var base64String = '';
      if (base64Arr.length > 1) {
        //如果是图片base64，去掉头信息
        base64String = base64Arr[1];
        imgtype = base64Arr[0].substring(base64Arr[0].indexOf(':') + 1, base64Arr[0].indexOf(';'));
      }
      // 将base64解码
      var bytes = atob(base64String);
      //var bytes = base64;
      var bytesCode = new ArrayBuffer(bytes.length);
      // 转换为类型化数组
      var byteArray = new Uint8Array(bytesCode);

      // 将base64转换为ascii码
      for (var i = 0; i < bytes.length; i++) {
        byteArray[i] = bytes.charCodeAt(i);
      }
      // 生成Blob对象（文件对象）
      return new Blob([bytesCode], {
        type: imgtype,
      });
    },
    // 删除视频
    uploadDelete() {
      this.uploadData.sourceURL = '';
      this.uploadStatue.statusText = '上传中,请勿退出';
      this.uploadStatue.percent = 0;
      this.sendVideoSucNum = 0;
      this.startUploadVideo = false;
    },
    // 平均数
    average(nums) {
      return nums.reduce((a, b) => a + b) / nums.length;
    },
    restData() {
      this.uploadData = {
        title: '', //标题
        newsType: 'SP', //上传类型 COVER-图集 SP-视频
        tags: [], //视频标签数组
        playTime: 0, //视频时长
        cover: '', //封面
        coverThumb: '', //封面小图
        seriesCover: [], // 图集图片地址
        via: '', //视频来源
        coins: 0, //观看金币
        size: 0, //文件大小
        mimeType: '', //文件类型
        actor: '', //演员名字
        sourceURL: '', //资源url 为SP时 必传
        sourceID: '', //上��视频成功后 返回���ID 为SP时必传
        filename: '', //文件名
        resolution: '', //分辨率
        ratio: 1, //视频比例
        md5: '', //文件摘要
        freeTime: 0, //免费观影时长
        location: {
          city: '',
        }, //地理位置信息
      };
      this.uploadData.title = '';
      this.uploadData.coins = 0;
      this.title = '';
      this.price = 0;
    },
    goTag() {
      this.$router.push('/topicMore');
    },
    goCity() {
      this.$router.push('/city');
    },
    async publish() {
      this.uploadData.title = this.title;
      this.uploadData.tags = this.tags.map((e) => e.id);
      this.uploadData.location.city = this.location;
      this.uploadData.coins = parseInt(this.price);
      if (this.$route.query.tId) {
        this.uploadData.taskID = this.$route.query.tId;
      }

      if (this.uploadData.title.length == 0) {
        Toast('请输入标题');
        return;
      }
      if (!this.uploadData.sourceURL) {
        Toast('请上传视频或等视频上传完毕');
        return;
      }
      if (!this.uploadData.cover) {
        Toast('请上传封面');
        return;
      }

      if (this.uploadData.location.city == '选择城市') {
        Toast('请选择城市');
        return;
      }
      if (this.uploadData.coins > 10000) {
        Toast('作品金额最高只能设置10000金币');
        return;
      }
      if (this.uploadData.tags.length == 0) {
        Toast('请选择标签');
        return;
      }
      if (this.uploadData.playTime < 30) {
        Toast('视频时长不能小于30秒');
        return;
      }
      if (this.uploadStatue.statusText != '上传成功' && this.uploadStatue.statusText != '上传失败') {
        Toast('请等待视频上传完成');
        return;
      }
      if (this.uploadStatue.statusText == '上传失败') {
        Toast('视频上传失败，请重新上传');
        return;
      }
      try {
        this.isLoadingShow = true;
        let res = await this.$Api(publishPost, this.uploadData);
        this.isLoadingShow = false;
        if (res.code == 200) {
          this.restData();
          this.uploadDelete();
          this.$store.commit('commnity/CLEARALL_TAGS');
          this.$router.replace('/creationCenter');
          Toast('发布成功');
          return;
        }
        this.promptShow = true;
        // Toast(res.tip || "发布失败");
      } catch (e) {
        this.isLoadingShow = false;
      }
    },
  },
  beforeDestroy() {
    this.requestAll = [];
    this.retry_request = -1;
  },
};
</script>
<style scoped lang="scss">
.upload_graphocs_warp {
  height: 100%;
  width: 100%;
  .content {
    background-color: #ffffff;
    padding: 0 12px;
  }
  .subTitle {
    font-size: 16px;
    padding: 10px 0 11px 0;
    color: #333333;
    // text-align: center;
  }

  .update-box {
    width: 100%;
    height: 95px;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #666666;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .upload-icon {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    svg {
      font-size: 32px;
    }
    span {
      padding-top: 2px;
    }
  }

  .info_video {
    width: 100%;
    text-align: center;
    padding: 5px 0;
    font-size: 12px;
  }

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
      text-align: right;
      font-size: 14px;
      color: #999999;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    background-color: #ffffff;

    .input_box {
      padding: 10px 16px 8px;
      background-color: #f5f5f5;
    }

    .titleText {
      background: #ffffff;
      border-radius: 8px;

      /deep/ .van-field__control {
        color: #666666;
        overflow: hidden;
      }

      /deep/ .van-field__control {
        font-size: 16px;
      }

      ::placeholder {
        color: #939bac;
        font-size: 14px;
      }
    }

    .up_box {
      padding: 0 0 5px 0;
      display: flex;

      .up_img_box {
        text-align: left;
        flex: 1;
        width: 100%;
        height: 95px;
        svg {
          font-size: 32px;
        }
        .text {
          padding-top: 2px;
        }
        /deep/ .addBox {
          width: 100%;
          height: 95px;
        }
        /deep/ .van-image {
          border-radius: 6px;
          overflow: hidden;
        }
        /deep/ .van-uploader,
        /deep/ .van-uploader__input-wrapper,
        /deep/.addBox {
          width: 100%;
        }
        /deep/ .van-uploader__preview {
          width: 100%;
          height: 95px;
          border-radius: 6px;
        }

        /deep/ .van-image {
          width: 100%;
          height: 95px;
          border-radius: 6px;
          border: 1px solid #666666;
        }
        h2 {
          padding-bottom: 5px;
          font-size: 17px;
        }
      }

      .up_video_box {
        flex: 1;
        text-align: left;
        padding-left: 10px;
        .upload_progres {
          width: 100%;
          height: 100%;
          .upload_tip {
            padding-top: 4px;
            font-size: 12px;
            text-align: center;
          }
          /*进度条*/
          .progress-wrap {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            position: relative;
            .upload_text {
              width: 100%;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              color: #fff;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              font-weight: bold;
              svg {
                width: 24px;
                height: 24px;
              }
              span {
                display: inline-block;
                font-size: 12px;
                font-weight: bold;
                padding-top: 5px;
              }
            }
          }
          .video_close {
            position: absolute;
            top: 0;
            right: 0;
            svg {
              font-size: 22px;
            }
          }
          /*进度条底层背景样式*/
          .progress-inner {
            height: inherit;
            background: #ffc0d1;
            border-radius: 8px;
          }
          /*进度层样式效果，使用动画效果*/
          .progress-nums {
            height: inherit;
            border-radius: 6px;
            background: repeating-linear-gradient(-45deg, #f093ac 25%, #ff678f 0, #ff678f 50%, #f093ac 0, #f093ac 75%, #ff678f 0);
            background-size: 16px 16px;
            animation: panoramic 30s linear infinite;
          }
          .active {
            background: #ff678f;
            animation: none;
          }
          /*定义动画*/
          @keyframes panoramic {
            to {
              background-position: 200% 0;
            }
          }
        }
        /deep/ .van-progress {
          height: 97px;
        }
        /deep/ .van-progress__pivot {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    .location_box {
      padding-top: 28px;

      .location_text {
        font-size: 16px;
        color: #333333;

        > svg {
          padding-right: 4px;
        }
      }

      .location {
        font-size: 14px;
        color: #999999;
        max-width: 200px;
        display: flex;
        align-items: center;

        > svg {
          font-size: 16px;
          flex-shrink: 0;
        }
      }
    }

    .tipic {
      padding: 20px 0 10px 0;
      align-items: center;

      h2 {
        font-size: 17px;
      }

      .more {
        font-size: 14px;
        color: #999999;
        svg {
          font-size: 16px;
        }
      }
    }

    .selectTitle {
      font-size: 16px;
      color: #666666;
      svg {
        font-size: 18px;
        padding-right: 4px;
      }
    }

    .tipic_content {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      height: 24px;
      line-height: 24px;

      .tipic_item {
        height: 24px;
        font-size: 12px;
        background: #e8e8e8;
        border-radius: 20.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        color: #666666;
        margin-right: 5px;
        margin-bottom: 5px;
        > svg {
          padding-left: 10px;
        }
        .tipc_text {
          padding-left: 4px;
          padding-right: 28px;
        }

        .tic_close {
          position: absolute;
          top: 4px;
          right: 8px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          > svg {
            font-size: 16px;
          }
        }
      }
    }

    > h2 {
      font-size: 17px;
      padding: 20px 0 10px 0;
    }
  }

  .publish_box {
    position: fixed;
    bottom: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 540px;

    .publish {
      width: 344px;
      height: 38px;
      color: white;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ff678f;
      border-radius: 4px;
    }
  }

  //  大赏
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter,
  .fade-leave-to

  /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .reward_pop_box {
    position: fixed;
    max-height: 100%;
    background-color: #fff;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    z-index: 12;
    border-radius: 17px;
  }

  /deep/ .van-overlay {
    z-index: 11;
  }

  .reward_pop {
    width: 294px;
    background: url('../../../assets/png/reward_bg.png') no-repeat;
    background-size: cover;
    border-radius: 17px;
    color: #171717;
    padding: 0 15px 24px;
    position: relative;

    h2 {
      font-size: 16px;
      padding: 18px 0 20px 0;
      text-align: center;
      color: #333333;
    }

    .reward {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 8px;
      grid-column-gap: 8px;

      .reward_item {
        text-align: center;
        border-radius: 4px;
        background: #fff4f7;
        height: 39px;
        line-height: 39px;
        font-size: 18px;

        /deep/ .van-field {
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          background: #fff4f7;
        }

        /deep/ .van-field__control {
          text-align: center;
        }
      }

      .active {
        background: #ff678f;
        color: #ffffff;
      }
    }

    .balance {
      font-size: 12px;
      padding: 16px 0 25px 0;
      color: #666666;
    }

    .reward_btn {
      width: 228px;
      height: 36px;
      line-height: 36px;
      background: #ff678f;
      font-size: 15px;
      text-align: center;
      border-radius: 20px;
      color: white;
      margin: 0 auto;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
    }
  }

  .close_box {
    position: absolute;
    bottom: -70px;
    width: 100%;
    display: flex;
    justify-content: center;

    .close {
      svg {
        font-size: 33px;
      }
    }
  }

  .F5164E {
    color: #f5164e;
  }

  .green {
    color: #00b056;
  }
}

.dialog {
  height: 4.43rem;
  border-radius: 0.2rem;
  background: none;

  .dialogContent {
    background-image: url('../../../assets/png/promptShowBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 7.04rem;
    height: 3.52rem;
    border-radius: 0.2rem;

    .dialogTitle {
      font-size: 0.4rem;
      color: rgb(132, 132, 132);
      display: flex;
      justify-content: center;
      padding-top: 0.3rem;
    }

    .dialogText {
      font-size: 0.38rem;
      color: rgb(100, 100, 100);
      padding-top: 0.18rem;
      font-weight: bold;
      text-align: center;

      .vip {
        font-size: 0.45rem;
        color: rgb(254, 127, 15);
      }
    }

    .btnGroup {
      width: 100%;
      display: flex;
      position: absolute;
      top: 2.28rem;
      display: flex;
      justify-content: center;

      .toBinding {
        //margin-left: 0.76rem;
      }

      .toPrepaid {
        margin-left: 0.22rem;
      }

      .btn {
        width: 2.76rem;
        height: 0.84rem;
      }
    }
  }

  .closeBtn {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 99999;

    img {
      width: 0.71rem;
      height: 0.71rem;
    }
  }
}
</style>
